import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/revealing/hero"
import Layers from "components/sections/revealing/layers"
import Explore from "components/sections/parsing/explore"
import DataPoints from "components/sections/revealing/dataPoints"
import Advantages from "components/sections/revealing/advantages"
import Tools from "components/sections/revealing/tools"
import Trial from "components/common/trial"
import Testimonials from "components/sections/revealing/testimonials"

const RevealingPage = ({ data }) => {
  const { frontmatter } = data.revealing.edges[0].node

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: frontmatter.useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout headerContent={data.header.edges} footerContent={data.footer.edges}>
        <SEO
          frontmatter={frontmatter}
        />
        <Hero content={data.hero.edges} />
        {/* Articles is populated via Medium RSS Feed fetch */}
        {/* <About content={data.about.edges} /> */}
        {/*<Clients className="businesses" content={data.clients.edges} />*/}
        <Layers content={data.layers.edges} />
        <Advantages content={data.advantages.edges} />
        <Explore content={data.explore.edges} />
        <DataPoints />
        <Testimonials content={data.testimonials.edges} />
        <Tools content={data.tools.edges} />
        
      </Layout>
    </GlobalStateProvider>
  )
}

RevealingPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default RevealingPage

export const pageQuery = graphql`
  {
    revealing: allMdx(filter: { fileAbsolutePath: { regex: "/revealing/revealing/" } }) {
      edges {
        node {
          frontmatter {
            title
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/revealing/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            description
          }
        }
      }
    }
    layers: allMdx(filter: { fileAbsolutePath: { regex: "/revealing/layers/" } }) {
      edges {
        node {
          exports {
            shownItems
            items {
              title
              subtitle
              logo {
                childImageSharp {
                  gatsbyImageData(width: 50, quality: 90)
                }
              }
            }
          }
          frontmatter {
            title1
            title2
            description
          }
        }
      }
    }
    explore: allMdx(filter: { fileAbsolutePath: { regex: "/revealing/explore/" } }) {
      edges {
        node {
          frontmatter {
            title
            profileTitle
            jobTitle
            profileLink
            jobLink
          }
        }
      }
    }
    advantages: allMdx(filter: { fileAbsolutePath: { regex: "/revealing/advantages/" } }) {
      edges {
        node {
          exports {
            items {
              number
              subtitle
              logo {
                childImageSharp {
                  gatsbyImageData(width: 150, quality: 90)
                }
              }
              description
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    testimonials: allMdx(filter: { fileAbsolutePath: { regex: "/revealing/testimonials/" } }) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            jobTitle
            author
            photo {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            text
          }
        }
      }
    }
    tools: allMdx(filter: { fileAbsolutePath: { regex: "/revealing/tools/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          exports {
            shownItems
            tools {
              alt
              link
              logo {
                childImageSharp {
                  gatsbyImageData(width: 120, formats: [WEBP, AVIF], quality: 90)
                }
              }
              logoHover {
                childImageSharp {
                  gatsbyImageData(width: 120, formats: [WEBP, AVIF], quality: 90)
                }
              }
            }
          }
        }
      }
    }
    footer: allMdx(filter: { fileAbsolutePath: { regex: "/layout/footer/" } }) {
      edges {
        node {
          body
          frontmatter {
            label
            title
            titlePrefix
            subtitle
            subtitlePrefix
            buttonBookingText
            buttonBookingLink
            buttonSignupText
            buttonSignupLink
            copyRight
          }
          exports {
            items {
              name
              links {
                text
                link
                external
              }
            }
          }
        }
      }
    }
    header: allMdx(filter: { fileAbsolutePath: { regex: "/layout/header/" } }) {
      edges {
        node {
          frontmatter {
            demoButtonText
            demoButtonLink
            registerButtonText
            registerButtonLink
            bookingButtonText
            bookingButtonLink
          }
          exports {
            items {
              name
              modal {
                left {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                }
                right {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                  media {
                    name
                    photo
                    alt
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
